import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Tab, TabItem, Button } from 'sw-ui';

import COMPONENTS from '../../bi/constants/components';

import CircularLoaders from '../../components/loaders';
import { FlatButton } from '../../components/button';
import CartList from './components/cartList';
import Note from './components/note';

import styles from './styles/index.module.scss';

const LABELS = {
  ADD_CART: 'Добавить корзину',
  TO_COMPANY: 'к компании',
  CART: 'Корзины',
  NOTE: 'Блокнот',
};

class CartsAndNotesComponents extends Component {
  static propTypes = {
    appService: PropTypes.object.isRequired,
    cartsAndNotesService: PropTypes.object.isRequired,
    rightsService: PropTypes.object.isRequired,
    moveToCompany: PropTypes.func.isRequired,
    moveToEditCart: PropTypes.func.isRequired,
    moveToCreateCart: PropTypes.func.isRequired,
    moveToCreateTrip: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const initialState = this.props.cartsAndNotesService.get();

    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    const { appService, cartsAndNotesService } = this.props;
    const userId = appService.get().params.userId;
    const accountId = appService.get().params.accountId;
    const companyId = appService.get().params.companyId;

    this.unsubscribeCartsAndNotesServiceCb = cartsAndNotesService.subscribe(this.updateCartsAndNotes);
    cartsAndNotesService.loadCartsAndNotes(userId, accountId, companyId);
  }

  componentWillUnmount() {
    const { cartsAndNotesService } = this.props;

    if (this.unsubscribeCartsAndNotesServiceCb) this.unsubscribeCartsAndNotesServiceCb();

    cartsAndNotesService.clearCartsAndNotes();
  }

  handleOpenFormCreate = () => {
    const { appService, moveToCreateCart } = this.props;
    const userId = appService.get().params.userId;

    moveToCreateCart(userId);
  }

  updateCartsAndNotes = () => {
    const newState = this.props.cartsAndNotesService.get();

    this.setState({
      ...newState,
    });
  };

  moveToCompany = () => this.props.moveToCompany();

  renderLoading = () => (
    <CircularLoaders wrapClassName='content-loader-wrap' />
  );

  renderListCompanies = user => user.Companies.map((company, idx, companies) => (
    <span key={ company.CompanyId } className={ styles.company }>{
      companies.length === idx + 1 ? company.CompanyName : `${company.CompanyName},\u00A0\u00A0\u00A0`
    }</span>
  ));

  renderButtonCreateCart = () => {
    const { canCreateCart } = this.state;

    if (!canCreateCart) return null;

    return (
      <div className={ styles['add-cart'] }>
        <Button
          label={ LABELS.ADD_CART }
          theme={ COMPONENTS.BUTTON.THEME.SECOND }
          onClick={ this.handleOpenFormCreate }
        />
      </div>
    );
  }

  render() {
    const {
      loading,
      user,
      user: {
        Email,
      },
      carts,
      note,
      companies,
      analytics,
      projects,
      canCreateCart,
      showApprovalsDialog,
      loadingApprovals,
      approvalsList,
      employeeId,
      approvalName,
      selectedApprovers,
      commentForApprove,
      errorApprove,
      link,
      errorLink,
    } = this.state;

    const {
      appService,
      cartsAndNotesService,
      rightsService,
      moveToEditCart,
      moveToCreateTrip,
    } = this.props;

    if (loading) return this.renderLoading();

    return (
      <div className={ styles.wrap }>
        <div className={ styles.row }>
          <div className={ styles['col-2-3'] }>
            <div className={ styles['button-trip'] }>
              <div className={ styles['button-trip__left'] }>
                <FlatButton onClick={ this.moveToCompany }>
                  <i className='material-icons'>keyboard_backspace</i> { LABELS.TO_COMPANY }
                </FlatButton>
              </div>
            </div>
          </div>
        </div>
        <div className={ `${styles.panel} ${styles.user}` }>
          <div className={ styles.row }>
            <div className={ styles['col-1-5'] }>
              <div>{ user.UserName }</div>
            </div>
            <div className={ `${styles['col-4-5']} ${styles.companies}` }>
              { this.renderListCompanies(user) }
            </div>
          </div>
        </div>
        { this.renderButtonCreateCart() }
        <Tab>
          <TabItem title={ LABELS.CART }>
            <CartList
              carts={ carts }
              userId={ appService.get().params.userId }
              userEmail={ Email }
              cartsAndNotesService={ cartsAndNotesService }
              rightsService={ rightsService }
              appService={ appService }
              companies={ companies }
              projects={ projects }
              moveToEditCart={ moveToEditCart }
              moveToCreateTrip={ moveToCreateTrip }
              canCreateCart={ canCreateCart }
              showApprovalsDialog={ showApprovalsDialog }
              approvalsList={ approvalsList }
              approvalName={ approvalName }
              loadingApprovals={ loadingApprovals }
              selectedApprovers={ selectedApprovers }
              commentForApprove={ commentForApprove }
              errorApprove={ errorApprove }
              employeeId={ employeeId }
              analytics={ analytics }
              link={ link }
              errorLink={ errorLink }
            />
          </TabItem>
          <TabItem title={ LABELS.NOTE }>
            <Note
              note={ note }
            />
            <div />
          </TabItem>
        </Tab>
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <CartsAndNotesComponents
      appService={ opts.appService }
      cartsAndNotesService={ opts.cartsAndNotesService }
      rightsService={ opts.rightsService }
      moveToCompany={ opts.moveToCompany }
      moveToEditCart={ opts.moveToEditCart }
      moveToCreateCart={ opts.moveToCreateCart }
      moveToCreateTrip={ opts.moveToCreateTrip }
    />
  );

  return root;
};

export default renderComponents;
