import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sprintf } from 'sprintf-js';

import guid from '../../../../bi/utils/guid';
import decline from '../../../../bi/utils/decline';
import { formatDate, getMoment, trimTimeZone, timeWithoutMoment, dateWithoutMoment, weekWithoutMoment } from '../../../../bi/utils/formatDate';
import FareDetails from '../../../../components/FareDetails';
import AirlineItemSegment from '../AirlineItemSegment';
import ProviderName from '../ProviderName';

import styles from './styles/index.module.css';
import { DEFAULTTIME } from '../../../../constants/time';
import { AIRLINE_CLASSES_MAP } from '../../../../bi/constants/airline';
import SYMBOLS from '../../../../bi/constants/symbols';

const getRateName = (metaFare, metaClass) => {
  const airlineClass = AIRLINE_CLASSES_MAP.get(metaClass);

  if (!metaFare.Name && !airlineClass) {
    return '';
  }

  if (!metaFare.Name) {
    return airlineClass;
  }

  if (!airlineClass || metaFare.Name.toLowerCase().includes(airlineClass.toLowerCase())) {
    return metaFare.Name;
  }

  return `${airlineClass} (${metaFare.Name})`;
};

export default class AirlineItem extends Component {
  static propTypes = {
    providerName: PropTypes.string,
    route: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    fromTrip: PropTypes.bool,
    pnr: PropTypes.string,
  };

  static defaultProps = {
    providerName: '',
    fromTrip: false,
    pnr: null,
  };

  state = {
    isShowSegments: false,
  };

  toggleSegments = () => this.setState({
    isShowSegments: !this.state.isShowSegments,
  });

  renderSegments = () => {
    const { fromTrip, route } = this.props;

    const segmentsHtml = route.Segments.map((segment, index) => (
      <AirlineItemSegment
        key={ `segment_${guid()}_${index}` }
        fromTrip={ fromTrip }
        segment={ segment }
      />
    ));

    return (
      <div
        onClick={ this.toggleSegments }
        className={ styles.segments }
      >
        { segmentsHtml }
      </div>
    );
  };

  renderPNR = () => {
    const { meta: { TicketsExtended }, pnr } = this.props;

    if (TicketsExtended) {
      const items = [];
      TicketsExtended.forEach((item) => {
        items.push(`${item.PNR} / ${item.PNRLocator}`);
      });

      return TicketsExtended && (
        <div className={ styles.pnr }>
          <span>{ items.join(', ') }</span>
        </div>);
    } else if (pnr) {
      return (
        <div className={ styles.pnr }>
          <span>{ pnr }</span>
        </div>);
    }

    return null;
  };

  renderRate = () => {
    const { meta: { Class, Fare } } = this.props;

    if (!Fare) {
      return null;
    }

    const customTitle = getRateName(Fare, Class);

    return (
      <FareDetails
        title
        customTitle={ customTitle }
        fare={ Fare }
        separateSymbol={ SYMBOLS.COMMA }
        asItem
      />);
  };

  secondsToLabel = (value, period) => {
    const minutes = Math.floor(value / 60) % 60;
    const hours = Math.floor(value / 3600);

    return sprintf(period ? '%d ч. %d м.' : '%02d:%02d', hours, minutes);
  };

  render() {
    const {
      providerName,
      route,
      fromTrip,
    } = this.props;

    const {
      isShowSegments,
    } = this.state;

    const firstSegment = route.Segments[0];
    const lastSegment = route.Segments[route.Segments.length - 1];

    const changeCount = route.Segments.length - 1;
    const transferHtml = changeCount ? (
      <span
        onClick={ this.toggleSegments }
        className={ styles['segments-toggle'] }
      >
        <span className={ styles['exchange-count-text'] }>
          { changeCount } { decline(changeCount, ['пересадка', 'пересадки', 'пересадок']) }
        </span>
        <i className={ `${styles['exchange-count-icon']} material-icons` }>
          { isShowSegments ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }
        </i>
      </span>
    ) : 'Без пересадок';

    const codeAirline = lastSegment.Airline.ID || lastSegment.Airline.Code;

    let departureTime = null;
    let arrivalTime = null;
    let departureDate = null;
    let arrivalDate = null;
    let departureWeek = null;
    let arrivalWeek = null;
    let duration = null;

    // Всё потому, что модель данных разная
    if (fromTrip) {
      const depDate = firstSegment.DepartureDate ? getMoment(trimTimeZone(firstSegment.DepartureDate)) : null;
      const arrDate = lastSegment.ArrivalDate ? getMoment(trimTimeZone(lastSegment.ArrivalDate)) : null;

      departureTime = formatDate(depDate, DEFAULTTIME);
      arrivalTime = formatDate(arrDate, DEFAULTTIME);
      departureDate = formatDate(depDate);
      arrivalDate = formatDate(arrDate);
      departureWeek = formatDate(depDate, 'dd');
      arrivalWeek = formatDate(arrDate, 'dd');
      duration = route.Duration && route.Duration > 0 ? this.secondsToLabel(route.Duration * 60, true) : null;
    } else {
      departureTime = timeWithoutMoment(firstSegment.DepartureTime);
      arrivalTime = timeWithoutMoment(lastSegment.ArrivalTime);
      departureDate = dateWithoutMoment(firstSegment.DepartureTime);
      arrivalDate = dateWithoutMoment(lastSegment.ArrivalTime);
      departureWeek = weekWithoutMoment(firstSegment.DepartureTime);
      arrivalWeek = weekWithoutMoment(lastSegment.ArrivalTime);
      duration = route.Duration && route.Duration > 0 ? this.secondsToLabel(route.Duration, true) : null;
    }

    return (
      <div className={ styles.item }>
        <div className={ styles.row }>
          <div className={ `${styles.col} ${styles.col_departure}` }>
            <h3>
              { `${firstSegment.DepartureCity}, ` }
              { firstSegment.DepartureAirport.ID || firstSegment.DepartureAirport.Code }
            </h3>
            <h3 className={ styles.time }>{ departureTime }</h3>
            <div className={ styles.details }>
              { `${departureDate}, ` }
              <span className={ styles.capitalize }>{ departureWeek }</span>
            </div>
          </div>
          <div className={ `${styles.col} ${styles.col_airline}` }>
            <div>
              { lastSegment.Airline.Name } ({`${codeAirline} ${lastSegment.FlightNumber}`})
            </div>
            <div className={ styles.duration }>
              { duration }
            </div>
            <div className={ styles.details }>
              { transferHtml }
            </div>
          </div>
          <div className={ `${styles.col} ${styles.col_arrival}` }>
            <h3>
              { `${lastSegment.ArrivalCity}, ` }
              { lastSegment.ArrivalAirport.ID || lastSegment.ArrivalAirport.Code }
            </h3>
            <h3 className={ styles.time }>{ arrivalTime }</h3>
            <div className={ styles.details }>
              { `${arrivalDate}, ` }
              <span className={ styles.capitalize }>{ arrivalWeek }</span>
            </div>
          </div>
        </div>
        { changeCount && isShowSegments ? this.renderSegments() : null }
        <div className={ styles['voucher-details'] }>
          { this.renderRate() }
          { this.renderPNR() }
        </div>
        <ProviderName providerName={ providerName } />
      </div>
    );
  }
}
