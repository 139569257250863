import React from 'react';
import PropTypes from 'prop-types';
import { sprintf } from 'sprintf-js';

import { formatDate, trimTimeZone, timeWithoutMoment, dateWithoutMoment, weekWithoutMoment } from '../../../../bi/utils/formatDate';

import styles from './styles/index.module.css';
import { FORMATDATETIME } from '../../../../constants/time';

const secondsToLabel = (value, period) => {
  const minutes = Math.floor(value / 60) % 60;
  const hours = Math.floor(value / 3600);

  return sprintf(period ? '%d ч. %d м.' : '%02d:%02d', hours, minutes);
};

const AirlineItemSegment = ({ fromTrip, segment }) => {
  const changeDurationHtml = segment.ChangeDuration > 0 ? (
    <div className={ styles.change }>
      <div className={ styles['change-title'] }>Пересадка</div>
      <div className={ styles['change-duration'] }>{ secondsToLabel(segment.ChangeDuration, true) }</div>
    </div>
  ) : null;

  let countStyle = '';
  let airlineId = '';
  let departureAirportId = null;
  let arrivalAirportId = null;
  let departureTime = null;
  let arrivalTime = null;
  let departureDate = null;
  let arrivalDate = null;
  let departureWeek = null;
  let arrivalWeek = null;

  // Всё потому, что модель данных разная
  if (fromTrip) {
    const depDate = segment.DepartureDate ? formatDate(trimTimeZone(segment.DepartureDate)) : null;
    const arrDate = segment.ArrivalDate ? formatDate(trimTimeZone(segment.ArrivalDate)) : null;

    airlineId = segment.Airline.Code;
    departureAirportId = segment.DepartureAirport.Code ? `, ${segment.DepartureAirport.Code}` : null;
    arrivalAirportId = segment.ArrivalAirport.Code ? `, ${segment.ArrivalAirport.Code}` : null;
    departureTime = formatDate(depDate, FORMATDATETIME);
    arrivalTime = formatDate(arrDate, FORMATDATETIME);
    departureDate = formatDate(depDate, 'D MMMM');
    arrivalDate = formatDate(arrDate, 'D MMMM');
    departureWeek = formatDate(depDate, 'dd');
    arrivalWeek = formatDate(arrDate, 'dd');

    countStyle = styles.not_count;
  } else {
    airlineId = segment.Airline.ID;
    departureAirportId = segment.DepartureAirport.ID ? `, ${segment.DepartureAirport.ID}` : null;
    arrivalAirportId = segment.ArrivalAirport.ID ? `, ${segment.ArrivalAirport.ID}` : null;
    departureTime = timeWithoutMoment(segment.DepartureTime);
    arrivalTime = timeWithoutMoment(segment.ArrivalTime);
    departureDate = dateWithoutMoment(segment.DepartureTime, 'D MMMM');
    arrivalDate = dateWithoutMoment(segment.ArrivalTime, 'D MMMM');
    departureWeek = weekWithoutMoment(segment.DepartureTime);
    arrivalWeek = weekWithoutMoment(segment.ArrivalTime);
  }

  return (
    <div className={ `${styles.item} ${countStyle}` }>
      <div className={ styles.row }>
        <div className={ styles.logo }>
          <img
            className={ styles['airline-logo'] }
            src={ `/static/media/images/airline/${airlineId}.png` }
            title={ segment.Airline.Name }
            alt={ segment.Airline.Name }
          />
        </div>
        <div className={ `${styles.row} ${styles['row-item']}` }>
          <div className={ `${styles.col} ${styles.col_departure}` }>
            <div className={ styles.date }>
              { `${departureDate}, ` }
              <span className={ styles.capitalize }>{ departureWeek }</span>
            </div>
            <h3>{ departureTime }</h3>
            <div className={ styles['airport-departure'] }>
              <div>
                <b>{ segment.DepartureCity }</b>
                { departureAirportId }
              </div>
              <div className={ styles['airport-name'] }>{ segment.DepartureAirport.Name }</div>
            </div>
          </div>
          <div className={ `${styles.col} ${styles.col_airline}` }>
            <div className={ styles.airline }>
              <span className={ styles['airline-name'] }>{ segment.Airline.Name }</span>
              <span className={ styles['airline-id'] }>{ ` ${airlineId} ${segment.FlightNumber} ` }</span>
              <span className={ styles['aircraft-name'] }>{ segment.AircraftName }</span>
            </div>
            <div className={ styles.icon }>
              <span className='smartway-airplane icon' />
            </div>
          </div>
          <div className={ `${styles.col} ${styles.col_arrival}` }>
            <div className={ styles.date }>
              { `${arrivalDate}, ` }
              <span className={ styles.capitalize }>{ arrivalWeek }</span>
            </div>
            <h3>{ arrivalTime }</h3>
            <div className={ styles['airport-arrival'] }>
              <div>
                <b>{ segment.ArrivalCity }</b>
                { arrivalAirportId }
              </div>
              <div className={ styles['airport-name'] }>{ segment.ArrivalAirport.Name }</div>
            </div>
          </div>
        </div>
      </div>
      { changeDurationHtml }
    </div>
  );
};

AirlineItemSegment.propTypes = {
  fromTrip: PropTypes.bool,
  segment: PropTypes.object.isRequired,
};

AirlineItemSegment.defaultProps = {
  fromTrip: false,
};

export default AirlineItemSegment;
